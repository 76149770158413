<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Cargar Archivo del layout {{ layout.nombre }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                ref="archivo"
                v-model="archivo"
                label="Cargar archivo excel"
                prepend-icon="mdi-paperclip"
                show-size
                multiple
                accept=".xls,.xlsx"
                :rules="required"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="secondary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="cargar()"
          :disabled="!valid"
          >Cargar Archivo</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ArchivosService from "@/services/archivo.service";
import validationField from "@/util/validationField";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      layout: {
        nombre: undefined,
      },
      archivo: undefined,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de layouts"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"));
      console.log(this.layout);
    }
  },
  methods: {
    cargar() {
      this.loadingSave = true;
      
      let formFile = new FormData()
      for(let i=0; i< this.archivo.length;i++){
        formFile.append('archivo[]', this.archivo[i])
      }

      // ArchivosService.uploadFile(this.layout.id, window.btoa(unescape(encodeURIComponent( this.archivo ))) ).then(
      ArchivosService.uploadFile(this.layout.id, formFile ).then(
      (response) => {
          this.$router.push({ path: `/cargue-archivos/layouts/archivos` });
          console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );  
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts/archivos" });
    },
  },
};
</script>

<style>
</style>
